<div class="auth-main2 particles_js">
  <div class="auth_div vivify fadeInTop">
    <div class="card">
      <div class="body">
        <div class="login-img">
          <img
            class="img-fluid"
            src="./../../assets/logo/doctor1.png"
            style="width: 500px; height: 400px"
          />
        </div>
        <form
          class="form-auth-small"
          [formGroup]="loginForm"
          (ngSubmit)="submit()"
        >
          <div class="mb-3">
            <h4>
              Indian Association<br />
              Admin Panel
            </h4>
            <p class="lead">Login to your account</p>
          </div>
          <div class="form-group">
            <label for="signin-email" class="control-label sr-only"
              >Email</label
            >
            <input
              type="email"
              formControlName="email"
              class="form-control round"
              placeholder="Email"
              [ngClass]="{ 'is-invalid': isSubmitted && form.email.errors }"
            />
            <div
              *ngIf="isSubmitted && form.email.errors"
              class="invalid-feedback"
            >
              <div *ngIf="form.email.errors.required">Email is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="signin-password" class="control-label sr-only"
              >Password</label
            >
            <input
              type="password"
              formControlName="password"
              class="form-control round"
              [ngClass]="{ 'is-invalid': isSubmitted && form.password.errors }"
              placeholder="Password"
            />
            <div
              *ngIf="isSubmitted && form.password.errors"
              class="invalid-feedback"
            >
              <div *ngIf="form.password.errors.required">
                Password is required
              </div>
            </div>
          </div>
          <!-- <div class="form-group clearfix">
                        <label class="fancy-checkbox element-left">
                            <input type="checkbox">
                            <span>Remember me</span>
                        </label>								
                    </div> -->
          <button type="submit" class="btn btn-primary btn-round btn-block">
            LOGIN
          </button>
          <!-- <button disabled class="btn btn-primary btn-round btn-block" style="cursor: not-allowed;">PLEASE WAIT..</button> -->
          <!-- <div class="mt-4">
                        <span class="helper-text m-b-10"><i class="fa fa-lock"></i> <a routerLink="forgot">Forgot password?</a></span>
                        <span>Don't have an account? <a href="page-register.html">Register</a></span>
                    </div> -->
        </form>
        <div class="pattern">
          <span class="red"></span>
          <span class="indigo"></span>
          <span class="blue"></span>
          <span class="green"></span>
          <span class="orange"></span>
        </div>
      </div>
    </div>
  </div>
  <div id="particles-js"></div>
</div>
<!-- END WRAPPER -->
