import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../Auth/auth.service';
import { ApiService } from '../_api/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm : FormGroup
  isSubmitted = false;
  // isLoading :boolean = false;
  constructor(
    private _api : ApiService,
    private _fb : FormBuilder,
    private _as  :AuthService,
    private _toast : ToastrService,
    private _route  : Router
    ) {

      this.loginForm = this._fb.group({
        email : ['',[Validators.required]],
        password : ['',[Validators.required]]
      })
   }

   get form(){
     return this.loginForm.controls
   }

  ngOnInit(): void {
  }

  submit(){
    this.isSubmitted = true;
    // this.isLoading = true;
    if(this.loginForm.invalid){
      // this.isLoading = false;
      return
    }

    console.log(this.loginForm.value)
    this._api.isLogin(this.loginForm.value).subscribe((res:any)=>{
      console.log(res)
      // this.isLoading = false;
      if(res.status == 200){
        this._toast.success(res.result.message,"Success")
        // this._ds.addUserData(res.message.token)
        localStorage.setItem('token', res.result.token)
        this._as.sendToken(res.result.token)
        this._route.navigate(['/admin']).then(() => {
          window.location.reload();
        });
      }
      if(res.status == 404){
        this._toast.error(res.result.message, "!Error")
      }
    },(err:any)=>{
    this._toast.error(err, "!Error")
    })
  }
}
