import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ThisReceiver } from '@angular/compiler';
import { ErrorHandler, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _BaseUrl = "https://docb.metalsart.in/AdminApp"
  private BaseUrl = "https://docb.metalsart.in/explorerApp"
  // private _BaseUrl = "http://103.180.80.86:9304/AdminApp"

  token: any

  constructor(private _http: HttpClient) {
    this.token = localStorage.getItem('token')

  }
 
  //3 Discount Active List Api
  AllOrderList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/AllOrderListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //3 Discount Active List Api
  PlacedOrder() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/OrderListnewAPI/?value=2`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  CompleteOrder() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/OrderListnewAPI/?value=3`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }
  TodaysOrder() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TodaysOrderListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  isLogin(data: any) {

    return this._http.post(`${this._BaseUrl}/LoginAPI/`, data)
      .pipe(catchError(this.errorHandler))

  }
  // Portfolio API
PortfolioAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/PortfolioAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActivePortfolio() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/PortfolioAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}

getInactivePortfolio() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/PortfolioAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
deletePortfolio(_id:any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/PortfolioAPI/?id=`+_id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updatePortfolio(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/PortfolioAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
  // Owner API
OwnerAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/Owner/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveOwner() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/Owner/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//34 Get Inactive HomeSliderAPI
getInactiveOwner() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/Owner/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
deleteOwner(_id:any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/Owner/?id=`+_id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateOwner(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/Owner/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
// Co - Owner API
CownerAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/CoOwner/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveCowner() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/CoOwner/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//34 Get Inactive HomeSliderAPI
getInactiveCowner() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/CoOwner/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
deleteCowner(_id:any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/CoOwner/?id=`+_id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateCowner(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.patch(`${this._BaseUrl}/CoOwner/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
// Manager API
ManagerAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/Manager/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveManager() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/Manager/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//34 Get Inactive HomeSliderAPI
getInactiveManager() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/Manager/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
deleteManager(_id:any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/Manager/?id=`+_id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateManager(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/Manager/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}

// Ngo ABoutus
NgoaboutAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/NgoAboutusAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveNgoabout() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/NgoAboutusAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//34 Get Inactive HomeSliderAPI
getInactiveNgoabout() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/NgoAboutusAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
deleteNgoabout(_id:any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/NgoAboutusAPI/?id=`+_id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateNgoabout(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/NgoAboutusAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}


  //2  DiscountAddAPI Add  API
  discountAddAPi(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/DiscountAddAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 Discount Active List Api
  getActiveDiscountList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/DiscountActiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //4 Get Inactive List DiscountInactiveListAPI
  getDiscountInactiveList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/DiscountInactiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //5 **** DiscountDeleteAPI Delete APi

  deleteDiscount(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/DiscountDeleteAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }


  //6 **** discount Data Api
  DiscountDataApi(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/DiscountDataAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }



  //7 ****discount Update Api

  updateDiscountApi(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/DiscountUpdateAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }




  //8  Admin USer Add  API
  paymentMethodAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/paymetmethodAddAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //9 Amdin User Active List Api
  getActivepaymetmethodList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token

    });
    return this._http.get(`${this._BaseUrl}/paymetmethodActiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //10 Get Inactive List Amin user
  getInactivepaymetmethodList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/paymetmethodInactiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //11 **** AdminUSEr Delete APi

  deletepaymetmethod(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/paymetmethodDeleteAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //12 ****Admin User Update Api

  updatepaymetmethodApi(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/paymetmethodUpdateAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }   

  // 26 T & C Add  AP I
  DistributerAddAPi(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/DistributerAddAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }

  // 32MainCategory Add  AP I
  MainCategoryAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/MainCategoryAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }  
  getActiveMainCategoryList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/MainCategoryAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  } 
  getInactiveMainCategoryList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/MainCategoryAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }  
  deleteMainCategory(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/MainCategoryAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  } 
  MainCategoryDataApi() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/MainCategoryDataAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  updateMainCategory(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/MainCategoryAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
  // Review ANd Rating API
  ReviewAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ReviewsAndRatingAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }  
  getActiveReviewList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ReviewsAndRatingAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  } 
  getInactiveReviewList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ReviewsAndRatingAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }  
  deleteReview(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/ReviewsAndRatingAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  } 
  
  updateReview(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ReviewsAndRatingAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

  // Doctor API  
  LinkdoctorAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/GenerateLinkAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  // Ayush Doctor
  AyushDtrAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ayushApi/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  
  getActiveAyushDtr() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ayushApi/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }
 
  getInactiveAyushDtr() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ayushApi/?value=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //35 ****MainCategory Delete APi
  deleteAyushDtr(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/ayushApi/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  UpdateAyushDtr(data:any){
    const opt = new HttpHeaders({
      'content-Type':'application/json',
      'Authorization':this.token
    });
    return this._http.put(`${this._BaseUrl}/ayushApi/`,data,{
      headers:opt
    })
    .pipe(catchError(this.errorHandler))
  }
  // Doctor API
  DoctorAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/DoctorsAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  DoctorsAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/DoctorForTestAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  
  getActiveDoctorList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/DoctorsAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }
 
  getInactiveDoctorList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/DoctorsAPI/?value=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //35 ****MainCategory Delete APi
  deleteDoctor(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/DoctorsAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //36 ****MainCategory Data Api
  DoctorDataApi() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/DoctorsAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //37 ****SellerUser Update Api
  updatedoctor(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.put(`${this._BaseUrl}/DoctorsAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
  HospitalAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/HospitalAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }

  //Hospital Active List Api
  getActiveHospitalList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/HospitalAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //34 Get Inactive Hospital
  getInactiveHospitalList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/HospitalAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //35 ****Hospital Delete APi

  deleteHospital(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/HospitalAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }
  //36 ****Hospital Data Api
  HospitalDataApi() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/HospitalAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }
  //37 ****Hospital Update Api

  updatehospital(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.put(`${this._BaseUrl}/HospitalAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }
  // HomeSliderAPI

  HomesldrAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/HomeSliderAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  getActiveHomeslider() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/HomeSliderAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //34 Get Inactive HomeSliderAPI
  getInactiveHomesldrList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/HomeSliderAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //35 ****HomeSliderAPI Delete APi

  deleteHomeslider(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/HomeSliderAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }
  //36 ****HomeSliderAPI Data Api
  HomesliderApi() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/HomeSliderAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }
  //37 ****Homeslider Update Api

  updateHomeSlider(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/HomeSliderAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }
  // Logo Updates Api
  LogoupdateAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/LogoUpdateAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  getActiveLogoupdate() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/LogoUpdateAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //34 Get Inactive HomeSliderAPI
  getInactiveLogoupdateList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/LogoUpdateAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //35 ****HomeSliderAPI Delete APi

  deleteLogoupdate(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/LogoUpdateAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }
  //36 ****HomeSliderAPI Data Api
  LogoupdateApi() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/LogoUpdateAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }
  //37 ****Homeslider Update Api

  updateLogoupdate(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/LogoUpdateAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
  // Post API
  PostAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/PostAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  getActivePost() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/PostAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  getInactivePostList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/PostAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }  
  deletePost(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/PostAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  } 
  updatePost(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.put(`${this._BaseUrl}/PostAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
  // NGO ABOUT US 1st
  AboutngoAdd1(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/AboutNgoAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  ActiveAboutngo1() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/AboutNgoAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
InactiveAboutngo1() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/AboutNgoAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }  
  deleteAboutngo1(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/AboutNgoAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  } 
  updateAboutngo1(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.put(`${this._BaseUrl}/AboutNgoAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

  // Advertisment API
  AadvertismentAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/AdvertisementAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  getActiveAadvertisment() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/AdvertisementAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  getInactiveAadvertisment() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/AdvertisementAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }  
  deleteAadvertisment(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/AdvertisementAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  } 
  updateAadvertisment(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.put(`${this._BaseUrl}/AdvertisementAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

// EBOOK API
EbookAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/DownloadEbookAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveEbook() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/DownloadEbookAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
getInactiveEbook() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/DownloadEbookAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}  
deleteEbook(id:any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/DownloadEbookAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
} 
updateEbook(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/DownloadEbookAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
 
  // SLiderAPi
  SliderAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SliderAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  getActiveSlider() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SliderAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //34 Get Inactive HomeSliderAPI
  getInactiveSlider() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SliderAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //35 ****HomeSliderAPI Delete APi

  deleteSlider(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/SliderAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  } 
  LogoSlider() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SliderAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
   updateSlider(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SliderAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
// Blog API
BlogAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/BlogAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveBlog() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/BlogAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//34 Get Inactive HomeSliderAPI
getInactiveBlog() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/BlogAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
deleteBlog(_id:any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/BlogAPI/?id=`+_id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateBlog(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/BlogAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
// NGO Home Slider API
NgoAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/NgoHomeSliderAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveNgo() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/NgoHomeSliderAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//34 Get Inactive HomeSliderAPI
getInactiveNgo() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/NgoHomeSliderAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
deleteNgo(_id:any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/NgoHomeSliderAPI/?id=`+_id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateNgo(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/NgoHomeSliderAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}

// Team API
TeamAdd(data:any){
  const opt = new HttpHeaders({
    'content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/TeamAPI/`, data, {
headers:opt
  })
  .pipe(catchError(this.errorHandler))
}
getActiveTeam(){
  const opt = new HttpHeaders({
    'content-Type':'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/TeamAPI/?status=1`,{
    headers:opt
  })
  .pipe(catchError(this.errorHandler))
}
getInactiveTeam(){
  const opt = new HttpHeaders({
    'content-type':'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/TeamAPI/?status=0`, {
    headers : opt
  })
  .pipe(catchError(this.errorHandler))
}
deleteTeam(id:any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/TeamAPI/?id=`+id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateTeam(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/TeamAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}

// Coupon API
CouponAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/CoupanAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveCoupon() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/CoupanAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))

}

//34 Get Inactive HomeSliderAPI
getInactiveCoupon() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/CoupanAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
deleteCoupon(id:any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/CoupanAPI/?id=`+id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}

updateCoupon(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/CoupanAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
// Count History API
CountAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/CountHistoryAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveCount() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/CountHistoryAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
getInactiveCount() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/CountHistoryAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}

deleteCount(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/CountHistoryAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateCount(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/CountHistoryAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
getHospital() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/HospitalByIdListAPI/`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}

// DoctorType API
DoctorTypeAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/DoctorsTypeAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveDoctorType() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/DoctorsTypeAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
getInactiveDoctorType() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/DoctorsTypeAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}

deleteDoctorType(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/DoctorsTypeAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateDoctorType(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/DoctorsTypeAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
// Top Blog API
TopblogAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/TopBlogAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveTopBlog() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/TopBlogAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
getInactiveTopBlog() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/TopBlogAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}

deleteTopBlog(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/TopBlogAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateTopBlog(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/TopBlogAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}


// New Feed API
NewfeedAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/NewFeedsAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveNewfeed() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/NewFeedsAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))

}

//34 Get Inactive HomeSliderAPI
getInactiveNewfeed() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/NewFeedsAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//35 ****HomeSliderAPI Delete APi

deleteNewfeed(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/NewFeedsAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateNewfeed(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/NewFeedsAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))

}
 
  // Testmonial API
  TestmonialAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/TestimonialsAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  getActiveTestmonial() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TestimonialsAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }  
  getInactiveTestmonial() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TestimonialsAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  deleteTestmonial(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/TestimonialsAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }  

  updateTestmonial(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.put(`${this._BaseUrl}/TestimonialsAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
  // AddListing
  getAddListing() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this.BaseUrl}/AddlistAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }   
  deleteAddlisting(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this.BaseUrl}/AddlistAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }  

  // Testimonial Heding API
  TestmonialHedingAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/TestimonialsHeadingAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  getActiveTestmonialHeding() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TestimonialsHeadingAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //34 Get Inactive HomeSliderAPI
  getInactiveTestmonialHeding() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TestimonialsHeadingAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //35 ****HomeSliderAPI Delete APi

  deleteTestmonialHeding(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/TestimonialsHeadingAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }  

  updateTestmonialHeding(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.put(`${this._BaseUrl}/TestimonialsHeadingAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

  // Privancy Policym API
  PrivancypolicyAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/PrivacyPolicyAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  getActivePrivancypolicy() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/PrivacyPolicyAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //34 Get Inactive HomeSliderAPI
  getInactivePrivancypolicy() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/PrivacyPolicyAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  deletePrivancypolicy(_id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/PrivacyPolicyAPI/?id=`+ _id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  } 
  updatePrivancypolicy(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.put(`${this._BaseUrl}/PrivacyPolicyAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

  // Term & Condition ApI
  TermAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/TermsConditionAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  getActiveTerm() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TermsConditionAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //34 Get Inactive HomeSliderAPI
  getInactiveTerm() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TermsConditionAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  deleteTerm(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/TermsConditionAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  } 
  updateTerm(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.put(`${this._BaseUrl}/TermsConditionAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

  // YouTube API
  YoutubeAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/YoutubeAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  getActiveYoutube() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/YoutubeAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //34 Get Inactive HomeSliderAPI
  getInactiveYoutube() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/YoutubeAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //35 ****HomeSliderAPI Delete APi

  deleteYoutube(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/YoutubeAPI/?id=`+id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }  

  updateYoutube(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.put(`${this._BaseUrl}/YoutubeAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
  // Our Feature API
  OurfeatureAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/OurFeaturesAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  getActiveOurfeature() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/OurFeaturesAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //34 Get Inactive HomeSliderAPI
  getInactiveOurfeature() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/OurFeaturesAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //35 ****HomeSliderAPI Delete APi

  deleteOurfeature(id:any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/OurFeaturesAPI/?id=`+id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }  

  updateOurfeature(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.put(`${this._BaseUrl}/OurFeaturesAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

// NGO Heading API
NgoHeadingAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/NGOHeadingAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveNgoHeading() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/NGOHeadingAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
getInactiveNgoHeading() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/NGOHeadingAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
deleteNgoHeading(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/NGOHeadingAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}  
updateNgoHeading(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/NGOHeadingAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}

// Director API
DirectorAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/DirectorAndCoAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveDirector() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/DirectorAndCoAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
getInactiveDirector() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/DirectorAndCoAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
deleteDirector(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/DirectorAndCoAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}  
updateDirector(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/DirectorAndCoAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}


// PopupFuction API
PopupAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/PopUpFunctionAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActivePopup() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/PopUpFunctionAPI/?status=True`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}

getInactivePopup() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/PopUpFunctionAPI/?status=False`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}

deletePopup(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/PopUpFunctionAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}

updatePopup(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/PopUpFunctionAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
// NGO Team API
NgoTeamAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/NGOTeamMemberAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveNgoTeam() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/NGOTeamMemberAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))

}

//34 Get Inactive HomeSliderAPI
getInactiveNgoTeam() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/NGOTeamMemberAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//35 ****HomeSliderAPI Delete APi

deleteNgoTeam(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/NGOTeamMemberAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))

}  

updateNgoTeam(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/NGOTeamMemberAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
// Labs API
LabAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/labAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveLab() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/labAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//34 Get Inactive HomeSliderAPI
getInactiveLab() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/labAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//35 ****HomeSliderAPI Delete APi
deleteLab(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/labAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateLab(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/labAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}

// Blood Bank API
BloodbankAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/BloodBankAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveBloodbank() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/BloodBankAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//34 Get Inactive HomeSliderAPI
getInactiveBloodbank() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/BloodBankAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//35 ****HomeSliderAPI Delete APi
deleteBloodbank(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/BloodBankAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateBloodbank(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/BloodBankAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
// PLAN API
PlanAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/PlanAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActivePlan() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/PlanAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//34 Get Inactive HomeSliderAPI
getInactivePlan() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/PlanAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//35 ****HomeSliderAPI Delete APi
deletePlan(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/PlanAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updatePlan(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/PlanAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}


// Clinic API
ClinicAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/ClinicAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveClinic() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/ClinicAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//34 Get Inactive HomeSliderAPI
getInactiveClinic() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/ClinicAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//35 ****HomeSliderAPI Delete APi
deleteClinic(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/ClinicAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateClinic(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/ClinicAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}

// Pharmacy Store API
PharmacyAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/PharmacyStoreAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActivePharmacy() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/PharmacyStoreAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//34 Get Inactive HomeSliderAPI
getInactivePharmacy() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/PharmacyStoreAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//35 ****HomeSliderAPI Delete APi
deletePharmacy(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/PharmacyStoreAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updatePharmacy(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/PharmacyStoreAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
// Diagnostice Center API
DiagnosticeAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/DiagnosticCentersAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveDiagnostice() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/DiagnosticCentersAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//34 Get Inactive HomeSliderAPI
getInactiveDiagnostice() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/DiagnosticCentersAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//35 ****HomeSliderAPI Delete APi
deleteDiagnostice(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/DiagnosticCentersAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateDiagnostice(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/DiagnosticCentersAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
SpouseList() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/DoctorsAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}

// Company API
CompanyAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/CompanyAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveCompany() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/CompanyAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//34 Get Inactive HomeSliderAPI
getInactiveCompany() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/CompanyAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//35 ****HomeSliderAPI Delete APi
deleteCompany(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/CompanyAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateCompany(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/CompanyAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
// Ayush Store API
AyushstoreAdd(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/AyushStoresAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getActiveAyushstore() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/AyushStoresAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//34 Get Inactive HomeSliderAPI
getInactiveAyushstore() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/AyushStoresAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//35 ****HomeSliderAPI Delete APi
deleteAyushstore(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/AyushStoresAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateAyushstore(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/AyushStoresAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
// Gender API
getGender() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this.BaseUrl}/GenderList/`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))

}
  // Special Category API
  SpecialAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SpecialistCategoryAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  getActiveSpecial() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SpecialistCategoryAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }
  

  //34 Get Inactive HomeSliderAPI
  getInactiveSpecial() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SpecialistCategoryAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //35 ****HomeSliderAPI Delete APi

  deleteSpecial(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/SpecialistCategoryAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }
  updatespecial(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SpecialistCategoryAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
  // Gallary API
  GallaryAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/GalleryAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }  
  getActiveGallaryList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/GalleryAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  } 
  getInactiveGallaryList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/GalleryAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }  
  deleteGallary(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/GalleryAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  } 
 
  updateGallry(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.put(`${this._BaseUrl}/GalleryAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

  // 44 vendortypeAddAPI Add  AP I
  vendorTypeAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/vendortypeAddAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //45 Brnacd Active List Api
  getActiveVendortypeList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/vendortypeActiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }
  //46 Get Inactive List vendortype
  getInactiveVendortypeList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/vendortypeInActiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //47 **** vendortype Delete APi

  deleteVendortype(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/vendortypeDeleteAPI/`, data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //48 **** vendortype Data Api
  vendorTypeDataApi() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/vendortypeDataAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //49 ****vendortype Update Api
  updateVendortype(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/vendortypeUpdateAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }


  // 103  Offer  Add  AP I
  offerAddAPI(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/OffersAddAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //104 offer  Active List Api
  offerActiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/OffersActiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //104 offer Inactive list 
  offerInactiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/OffersInactiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //105 **** Offer  Delete APi
  deletesOffer(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/OffersDeleteAPI/`, data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //106 **** offer Data Api
  offerDataApi(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/OffersDataAPI/?id=` + data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }
  //107 ****offer Update Api
  updateOffer(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/OffersUpdateAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

  // 108 pack size  Add  AP I
  packSizeAddAPI(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/PackSizeAddAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //109 Pacj size  Active List Api
  packSizeActiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/PackSizeActiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }




  //110 pack size Inactive list 
  packSizeInactiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/packSizeInactiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //111 **** pack size  Delete APi

  deletesPackSize(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/PackSizeDeleteAPI/`, data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }


  //112 **** packsize Data Api
  packSizeDataApi(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/PackSizeDataAPI/?id=` + data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }



  //113 ****packSize Update Api

  updatePackSize(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/PackSizeUpdateAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }


  // 114 Product Stock size  Add  AP I
  productStockAddAPI(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ProductStockAddAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //115 Product Stock  Active List Api
  productStockActiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ProductStockActiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }




  //116 Product stock Inactive list 
  productStockInactiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ProductStockInactiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //117 **** Product stock  Delete APi

  deleteProductStock(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ProductStockDeleteAPI/`, data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }


  //118 **** Product Stock Data Api
  productStockDataApi(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ProductStockDataAPI/?id=` + data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }



  //119 **** Product Stock Update Api

  updateProductStock(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ProductStockUpdateAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }


  // 120 Pack size by weight  Add  AP I
  packByWeightAddAPI(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/PackSizeByWeightAddAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //121 packByWeight  Active List Api
  packByWeightActiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/PackSizeByWeightActiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }




  //122 packByWeight Inactive list 
  packByWeightInactiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/packSizeByWeightInactiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //123 **** packByWeight  Delete APi

  deletepackByWeight(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/PackSizeByWeightDeleteAPI/`, data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //124 **** Product Stock Update Api

  updatepackByWeight(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/PackSizeByWeightUpdateAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }


  // 125 sldier  Add  AP I
  // 


  // 131 socialMedia  Add  AP I
  socialMediaAddAPI(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SocialMediaAddAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //132 socialMedia  Active List Api
  socialMediaActiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SocialMediaListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }




  //133 socialMedia Inactive list 
  socialMediaInactiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SocialMediaInactiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //134 ****socialMedia Delete APi

  deleteSocialMedia(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SocialMediaDeleteAPI/`, data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }


  //135 **** socialMedia Data Api
  socialMediaDataApi(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SocialMediaDataAPI/?id=` + data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }



  //136 ****Sldier Update Api

  updateSocialMedia(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SocialMediaUpdateAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }



  // 137 ServiceAddAPI  Add  AP I
  serviceAddAPI(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ServiceAddAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //138 service  Active List Api
  ServiceActiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ServiceListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }




  //139 service Inactive list 
  ServiceInactiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ServiceInactiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //140 ****service Delete APi

  deleteService(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ServiceDeleteAPI/`, data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }


  //141 **** service Data Api
  ServiceDataApi(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ServiceDataAPI/?id=` + data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }



  //142 ****service Update Api

  updateService(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ServiceUpdateAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }


  //143 **** Sub Category List by Main Category Api

  subCategoryByMainCategoryApi(id: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SubCategoryByMainCategoryListAPI/?id=` + id,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

  //144 **** Product By Brand Api
  productByBrand(id: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ProductByBrandAPIList/?id=` + id,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }


  // 145 RackId  Add  AP I
  RackIdAddAPI(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/RackIdAddAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //146 RackId  Active List Api
  getRackIdActiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/RackIdListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }




  //147 RackId Inactive list 
  getRackIdInactiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/RackIdInactiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //148 ****RackId Delete APi

  deleteRackId(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/RackIdDeleteAPI/`, data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }
  //149 **** RackId Data Api
  RackIdDataApi(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/RackIdDataAPI/?id=` + data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //150 ****RackId Update Api
  updateRackId(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/RackIdUpdateAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

  //151 **** Sub Category List by Main Category Api

  productByMainCategoryListAPI(id: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ProductByMainCategoryListAPI/?id=` + id,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

  //152 **** ProductBySubCategoryListAPI Api
  productBySubCategory(id: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ProductBySubCategoryListAPI/?id=` + id,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }


  // 153 AboutUsAddAPI  Add  AP I
  AboutUsAddAPI(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/AboutUsAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }

  //153 AboutUs  Active List Api
  getAboutUsActiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/AboutUsAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //154 AboutUs Inactive list 
  getAboutUsInactiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/AboutUsAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //155 ****AboutUs Delete APi

  deleteAboutUs(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/AboutUsAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //157 ****AboutUs Update Api

  updateAboutUs(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/AboutUsAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
// City API
CityAddAPI(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/CityAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}

//153 AboutUs  Active List Api
getCityActiveListAPI() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/CityAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//154 AboutUs Inactive list 
getCityInactiveListAPI() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/CityAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}

//155 ****AboutUs Delete APi

deleteCity(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/CityAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateCity(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/CityAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}

// Coupon Code API
CouponCodeAddAPI(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/GenerateCoupanCodeAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}

//153 AboutUs  Active List Api
getActiveCouponCode() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/GenerateCoupanCodeAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//154 AboutUs Inactive list 
getInactiveCouponCode() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/GenerateCoupanCodeAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}

//155 ****AboutUs Delete APi

deleteCouponCode(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/GenerateCoupanCodeAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateCouponCode(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/GenerateCoupanCodeAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}


// State API
StateAddAPI(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/StateAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}

//153 AboutUs  Active List Api
getStateActiveListAPI() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/StateAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
//154 AboutUs Inactive list 
getStateInactiveListAPI() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/StateAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}

deleteState(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/StateAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateState(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/StateAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
getCategoryActiveListAPI() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/CategoryListAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
// Payment LIst
getPaymentList(){
  const opt = new HttpHeaders({
    'content-Type':'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this.BaseUrl}/OrderDetailAPI/`, {
    headers :opt
  })
  .pipe(catchError(this.errorHandler))
}

  // 158 Policy  Add  AP I
  PolicyAddAPI(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/PolicyAddAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //159 Policy  Active List Api
  getPolicyActiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/PolicyListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //160 Policy Inactive list 
  getPolicyInactiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/PolicyInactiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  deletePolicy(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/PolicyDeleteAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
 
  PolicyDataApi(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/PolicyDataAPI/?id=` + data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //163 ****Policy Update Api
  updatePolicy(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/PolicyUpdateAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
  // 164 RackId  Add  AP I
  FooterdAddAPI(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/FooterAddAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  //165 Footer  Active List Api
  getFooterActiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/FooterListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
//  Suggestion API
getSuggestionActive(){
  const opt = new HttpHeaders({
    'content-Type':'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/SuggestionAPI/`, {
    headers :opt
  })
  .pipe(catchError(this.errorHandler))
}
deleteSuggestion(id:any){
  const opt = new HttpHeaders({
    'content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/SuggestionAPI/?id=` + id, {
    headers:opt
  })
  .pipe(catchError(this.errorHandler))
}
//166 Footer Inactive list 
  getFooterInactiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/FooterInactiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }  
  deleteFooter(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/FooterDeleteAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //168 **** Footer Data Api
  FooterDataApi(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/FooterDataAPI/?id=` + data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
    //169 ****Footer Update Api
  updateFooter(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/FooterUpdateAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

  // 170 delivery boy  Add  AP I
  DeliveryBoyAddAPI(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/DeliveryBoyAddAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //171 DeliveryBoy  Active List Api
  getDeliveryBoyActiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/DeliveryBoyListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //172 DeliveryBoy Inactive list 
  getDeliveryBoyInactiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/DeliveryBoyInactiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //173 ****DeliveryBoy Delete APi
  deleteDeliveryBoy(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/DeliveryBoyDeleteAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //174 **** DeliveryBoy Data Api
  DeliveryBoyDataApi(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/DeliveryBoyDataAPI/?id=` + data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //175 ****DeliveryBoy Update Api
  updateDeliveryBoy(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/DeliveryBoyUpdateAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }


  // 170 ContactUs Add  AP I
  ContactUsAddAPI(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ContactUsAddAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }

  //171 DeliveryBoy  Active List Api
  getContactUsActiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ContactUsListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //172 ContactUs Inactive list 
  getContactUsInactiveListAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ContactUsInactiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //173 ****ContactUs Delete APi

  deleteContactUs(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ContactUsDeleteAPI/?id=`+ id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //174 **** ContactUs Data Api
  ContactUsDataApi(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ContactUsDataAPI/?id=` + data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //175 ****ContactUs Update Api
  updateContactUs(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ContactUsUpdateAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

  //176 ****getStockAlertAPI 
  getStockAlertAPI() {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/StockAlertAPI/`,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
  //177 ****MainCategoryByBrandListAPI 

  brandByMainCategoryList(id: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/MainCategoryByBrandListAPI/?id=` + id,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
  //178 ****OfferListAPI 
  getOfferList() {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/OfferListAPI/`,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
  getOrderList() {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/OrderListAPI/`,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
  customerList() {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/CustomerListAPI/`,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
  cartlistApi() {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/CartListAPI/`,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
  offerListApi() {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/OfferListAPI/`,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }
  offerInaciveList() {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/OfferInactiveListAPI/`,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

  ChangeOfferStatusAPI(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ChangeOfferStatusAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))
  }

  

  getSubCategoryXML() {
    return this._http.get(`${this._BaseUrl}/GetSubCategory/`)
      .pipe(catchError(this.errorHandler));  // catch error
  }
  getBrandXML() {
    return this._http.get(`${this._BaseUrl}/GetBrand/`)
      .pipe(catchError(this.errorHandler));  // catch error
  }
  getProductXML() {
    return this._http.get(`${this._BaseUrl}/GetProduct/`)
      .pipe(catchError(this.errorHandler));  // catch error
  }
  getProductStockXML() {
    return this._http.get(`${this._BaseUrl}/GetProductStock/`)
      .pipe(catchError(this.errorHandler));  // catch error
  }
  uploadMainCategoryXMl(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/MainCategorycsvAddAPI/`, data, {
      headers: optHeader
    })
      .pipe(catchError(this.errorHandler));  // catch error
  }
  uploadSubCategoryXMl(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SubCategorycsvAddAPI/`, data, {
      headers: optHeader
    })
      .pipe(catchError(this.errorHandler));  // catch error
  }
  uploadBrandXMl(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/BrandcsvAddAPI/`, data, {
      headers: optHeader
    })
      .pipe(catchError(this.errorHandler));  // catch error
  }
  uploadProductXMl(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ProductcsvAddAPI/`, data, {
      headers: optHeader
    })
      .pipe(catchError(this.errorHandler));  // catch error
  }
  uploadProductStockXMl(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ProductStockcsvAddAPI/`, data, {
      headers: optHeader
    })
      .pipe(catchError(this.errorHandler));  // catch error
  }  
  // 32MainCategory Add  AP I
  SetPaytmMidAPIAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SetPaytmMidAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  //33SetPaytmMidAPI Active List Api
  getActiveSetPaytmMidAPIList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SetPaytmMidAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  //34 Get Inactive ListSetPaytmMidAPI
  getInactiveSetPaytmMidAPIList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SetPaytmMidAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  deleteSetPaytmMidAPI(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SetPaytmMidAPI/`, data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  } 
  SetPaytmMidAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SetPaytmMidAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
  // 32MainCategory Add  AP I
  SetTwilioIdAPIAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SetTwilioIdAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }
  //33SetTwilioIdAPI Active List Api
  getActiveSetTwilioIdAPIList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SetTwilioIdAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }
// ADS STORY API
StoryAddAPI(data: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.post(`${this._BaseUrl}/AdsStoryAPI/`, data, {
    headers: opt
  })
    .pipe(catchError(this.errorHandler))
}
getStoryActiveListAPI() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/AdsStoryAPI/?status=1`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
getStoryInactiveListAPI() {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.get(`${this._BaseUrl}/AdsStoryAPI/?status=0`,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
deleteStory(id: any) {
  const opt = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.delete(`${this._BaseUrl}/AdsStoryAPI/?id=`+ id,
    {
      headers: opt
    })
    .pipe(catchError(this.errorHandler))
}
updateStory(data: any) {
  const optHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token
  });
  return this._http.put(`${this._BaseUrl}/AdsStoryAPI/`, data,
    {
      headers: optHeader
    })
    .pipe(catchError(this.errorHandler))
}
  //34 Get Inactive ListSetTwilioIdAPI
  getInactiveSetTwilioIdAPIList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SetTwilioIdAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //35 ****SetTwilioIdAPI Delete APi

  deleteSetTwilioIdAPI(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SetTwilioIdAPI/`, data,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //36 ****SetTwilioIdAPI Data Api
  SetTwilioIdAPI() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SetTwilioIdAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
